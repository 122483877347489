import React from 'react';
import Layout from '~layouts/layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import { newsfilterPageData } from '~data/filterPageData';
import PageSection from '~2-components/PageSection/PageSection';
import filteredListingData from '~data/newsFilteredListingData.json';
import FilteredListing from '~2-components/FilteredListing/FilteredListing';

const FilteredListingStatic = () => (
    <Layout>
        <RenderedContext.Provider value={newsfilterPageData}>
            <Header />
        </RenderedContext.Provider>

        <div className="page-content">
            <div className="container">
                <FilteredListing data={filteredListingData} tileType={'articles'} />
            </div>
        </div>
    </Layout>
);

export default FilteredListingStatic;
